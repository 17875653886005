/** @jsx jsx */
import { jsx, Box, Card, Image, Flex } from "theme-ui";
import { Link } from "gatsby";
import AppIcons from "sites-common/utils/icons";
// import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
// import { get } from "sites-common/utils/lodash";
import PropTypes from "prop-types";
import { DefaultButton } from "office-ui-fabric-react";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { navigate } from "@reach/router";

const goBack = () => navigate(-1);

const MenuItems = ({ items }) => (
  <Box
    p="4"
    sx={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: 4,
    }}
  >
    {items.map(
      ({ label, subLabel, to, icon, allow = true }) =>
        allow && (
          <Card
            key={label}
            sx={{
              width: "300px",
              height: "300px",
              boxShadow: "0px 3px 6px #00000029",
              opacity: 1,
              textAlign: "center",
              cursor: "pointer",
              borderRadius: "2px",
              border: "2px solid ",
              borderColor: "background",
              mb: 2,
              "&:hover": {
                border: "2px solid",
                borderColor: "primary",
              },
              backgroundColor: "background",
            }}
          >
            <Link to={to} sx={{ textDecoration: "none" }}>
              <Box
                sx={{
                  letterSpacing: " 0.24px",
                  color: "primary",
                  opacity: 1,
                  fontWeight: "bold",
                  padding: "20px 10px",
                  boxShadow: "0px 25px 0px -24px #bebdbd",
                }}
              >
                {label}
              </Box>
              <Box
                sx={{
                  pt: 4,
                }}
              >
                <Image
                  src={icon}
                  alt={label}
                  sx={{ height: "130px", width: "130px" }}
                />
              </Box>
              <Box
                sx={{
                  letterSpacing: " 0.24px",
                  color: "secondary",
                  opacity: 1,
                  px: "2",
                  py: "3",
                  fontSize: "14px",
                }}
              >
                {subLabel}
              </Box>
            </Link>
          </Card>
        )
    )}
  </Box>
);

MenuItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.node,
      allow: PropTypes.bool,
    })
  ).isRequired,
};

const Index = () => {
  // const { srcmProfile } = useAuth();

  const menuItems = [
    {
      label: "Online Event",
      subLabel: "Online Events Organised by me >>",
      to: "/my-events",
      icon: AppIcons.event_landing,
      // allow: get(srcmProfile, "isAtleastPractitioner"),
    },
    {
      label: "Event Tracker",
      subLabel: "Data Entry for Events Tracker >>",
      to: "/events-tracker",
      icon: AppIcons.approval_landing,
      // allow: get(srcmProfile, "isAtleastPractitioner"),
    },
  ];

  return (
    <div>
      <Flex sx={{ m: 3, justifyContent: "flex-end" }}>
        <Box>
          <DefaultButton
            styles={buttonStyles.default}
            iconProps={{ iconName: "Back" }}
            text="Back"
            onClick={goBack}
          />
        </Box>
      </Flex>
      <MenuItems items={menuItems} />
    </div>
  );
};

export default Index;
